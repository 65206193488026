<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { Material } from '../types';
import textSourceUrl from './text_source.png';
import infoTextUrl from './info_text.png';

export default defineComponent({
  props: {
    material: {
      type: Object as PropType<Material>,
      required: true,
    },
  },
  computed: {
    style() {
      if (this.material.type === 'text_source' || this.material.type === 'info_text') {
        return {
          backgroundColor: this.material.color,
          backgroundImage: `url(${this.material.type === 'text_source' ? textSourceUrl : infoTextUrl})`,
          backgroundPosition: 'center 35%',
          backgroundSize: '40%',
        };
      } else {
        return {
          backgroundColor: this.material.color,
          backgroundImage: `url(${this.material.thumbnail_path})`,
        };
      }
    },
  },
});
</script>

<template>
<li class="card">
  <router-link
    :to="`/materials/${material.id}`"
    class="card__link"
    :style="style"
    >
    <div class="card__body">
      <p class="card__text">
        {{ material.title }}
      </p>
    </div>
  </router-link>
</li>
</template>
