<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'pinia';
import tinymce from 'tinymce';

import generateMaterialHtml from './generateMaterialHtml';
import { Material } from '../types';
import { useExercisesStore } from '../chapter/exercises_store';

const FILE_EXTENSIONS = ['odt', 'ods', 'xls', 'doc', 'docx', 'xlsx'];
const INSERTABLE_TYPES = ['image', 'how_to', 'document', 'drawing', 'chart', 'text_source'];
const TEXT_TYPES = ['text_source', 'info_text', 'how_to'];

export default defineComponent({
  computed: {
    ...mapState(useExercisesStore, ['isLoading', 'scopedFilteredAndSearchedMaterials',
      'currentExerciseId']),
    isLoading() {
      return !this.currentItem;
    },
    currentItem() {
      return this.scopedFilteredAndSearchedMaterials.find((material: Material) =>
        material.id === Number(this.$route.params.id));
    },
    fileExtension() {
      return this.currentItem?.filename?.split('.').pop();
    },
    currentIndex() {
      return this.scopedFilteredAndSearchedMaterials.indexOf(this.currentItem);
    },
    hasPrevious() {
      return this.currentIndex > 0;
    },
    hasNext() {
      return this.currentIndex < (this.scopedFilteredAndSearchedMaterials.length - 1);
    },
    showPrintAction() {
      return !this.isAudio
        && !this.isVideo
        && !this.isThematicMap;
    },
    showPdfExport() {
      return !this.isAudio
        && !this.isVideo
        && !this.isThematicMap;
    },
    pdfPath() {
      return this.fileExtension === 'pdf'
        ? this.currentItem.asset_path
        : `/materials/${this.currentItem.wp_id}.pdf`;
    },
    showShortTitle() {
      return this.currentItem.short_title
        && this.currentItem.short_title !== this.currentItem.title;
    },
    showLink() {
      if (!this.currentItem.asset_path) {
        return false;
      }

      if (['image', 'drawing', 'map'].includes(this.currentItem.type)) {
        return true;
      }

      if (this.currentItem.type === 'document' && this.fileExtension !== 'pdf') {
        return true;
      }

      return false;
    },
    showImage() {
      return !FILE_EXTENSIONS.includes(this.fileExtension);
    },
    showAttachButton() {
      return this.currentExerciseExists
        && this.materialIsInsertable;
    },
    currentExerciseExists() {
      return this.currentExerciseId !== null;
    },
    materialIsInsertable() {
      return this.currentItem.is_pdf === false
        && INSERTABLE_TYPES.includes(this.currentItem.type);
    },
    isPdf() {
      return this.currentItem.type === 'document' && this.fileExtension === 'pdf';
    },
    isVideo() {
      return this.currentItem.type === 'video';
    },
    isAudio() {
      return this.currentItem.type === 'audio';
    },
    isThematicMap() {
      return this.currentItem.type === 'map' && this.currentItem.thematic_map_values == '1';
    },
    isBioMap() {
      return this.currentItem.type === 'bio_map';
    },
    isText() {
      return TEXT_TYPES.includes(this.currentItem.type);
    },
    isChart() {
      return this.currentItem.type === 'chart';
    }
  },
  methods: {
    ...mapActions(useExercisesStore, ['updateAnswer', 'updateRelatedAnswer']),
    handleClose() {
      this.$router.push('/materials');
    },
    handlePrevious() {
      const previousItem = this.scopedFilteredAndSearchedMaterials[this.currentIndex - 1];
      this.$router.push(`/materials/${previousItem.id}`);
    },
    handleNext() {
      const nextItem = this.scopedFilteredAndSearchedMaterials[this.currentIndex + 1];
      this.$router.push(`/materials/${nextItem.id}`);
    },
    resize(event: Event) {
      const iframe = event.target as HTMLIFrameElement;
      if (!iframe || !iframe.contentWindow) {
        return;
      }
      iframe.style.height = (iframe.contentWindow.document.body.scrollHeight + 15) + 'px';

      setTimeout(() => {
        if (iframe.contentWindow) {
          iframe.style.height = (iframe.contentWindow.document.body.scrollHeight + 15) + 'px';
        }
      }, 1000);
    },
    print() {
      window.print();
    },
    attach() {
      const editor = tinymce.activeEditor;
      if (!editor) {
        return;
      }
      const html = generateMaterialHtml(this.currentItem);
      if (!html) {
        return;
      }
      editor.insertContent(html);

      // Save updated content.
      const content = editor.getContent();
      if (editor.id === 'editor-answer') {
        this.updateAnswer(content);
      } else {
        this.updateRelatedAnswer(content);
      }

      // Navigate away.
      this.$router.push(`/exercises/${this.currentExerciseId}`);
    },
  },
});
</script>

<template>
<div class="c-modal" @keydown.esc="handleClose">
  <div class="c-modal__modal" tabindex="0" @keydown.esc="handleClose">
    <div v-if="isLoading" class="loading">
      <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    </div>
    <div v-else>
      <div class="c-modal__toolbar">
        <button class="button button--icon c-modal__button u-mr-small"
          data-test-id="materials-modal-close"
          :title="$t('general.close')"
          @click="handleClose" @keydown.esc="handleClose">
          <i class="icon fa fa-times" aria-hidden="true"></i>
        </button>

        <button class="button button--icon c-modal__button"
          :title="$t('general.prev')"
          @click="handlePrevious"
          :disabled="!hasPrevious">
          <i class="icon fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <button class="button button--icon c-modal__button"
          :title="$t('general.next')"
          @click="handleNext"
          :disabled="!hasNext">
          <i class="icon fa fa-chevron-right" aria-hidden="true"></i>
        </button>

        <div class="c-modal__push-right">
          <button v-if="showAttachButton" @click="attach"
            class="button button--icon c-modal__button"
            :title="$t('components.materials.insert_into_exercise')">
            <i class="icon fa fa-paperclip" aria-hidden="true"></i>
          </button>

          <a v-if="showPrintAction" :href="pdfPath"
            class="u-ml-tiny"
            :title="$t('general.download')"
            target="_blank" rel="noreferrer">
            <i class="icon fa fa-file-pdf-o" aria-hidden="true"></i>
          </a>
        </div>
      </div>

      <div class="c-modal__content u-mt">
        <h3>
          <small v-if="showShortTitle">{{ currentItem.short_title }}</small>
          <br v-if="showShortTitle" />
          <span>{{ currentItem.title }}</span>
        </h3>

        <a v-if="showLink" :href="currentItem.asset_path" target="_blank" rel="noreferrer">
          <img v-if="showImage" class="img-responsive" :src="currentItem.asset_path" :alt="currentItem.title" />
          <span v-else v-bind="currentItem.filename.split('/')[1]"></span>
        </a>

        <iframe v-if="isPdf" :src="currentItem.asset_path" @load="resize"
          style="width: 100%; min-height: 620px;"></iframe>

        <iframe v-if="isVideo" :src="`/videos/${currentItem.id}`" @load="resize"
          allow="fullscreen" frameborder="0" style="width: 100%;"></iframe>

        <div class="caption" :class="{ 'text': isText, 'chart': isChart }">
          <div v-if="currentItem.chart" v-html="currentItem.chart"></div>

          <div v-if="currentItem.howto_text_formal" v-html="currentItem.howto_text_formal"></div>

          <p v-if="currentItem.introduction">
            <strong v-html="currentItem.introduction"></strong>
          </p>

          <p v-if="currentItem.text" v-html="currentItem.text"></p>

          <div v-if="currentItem.caption">
            <small v-html="currentItem.caption"></small>
          </div>

          <p v-if="currentItem.written_by">
            <small v-html="currentItem.written_by"></small>
          </p>
        </div>

        <iframe v-if="isThematicMap" :src="`/maps/${currentItem.id}`" @load="resize"
          allow="fullscreen" frameborder="0" style="width: 100%;"></iframe>

        <iframe v-if="isBioMap" :src="`/bio_maps/${currentItem.id}`" @load="resize"
          allow="fullscreen" frameborder="0" style="width: 100%;"></iframe>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.button {
}

.icon {
  font-size: 18px;
}
</style>
