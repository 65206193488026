<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: { saving: Boolean },
});
</script>

<template>
<div>
  <button type="button"
    @click="$emit('save')"
    class="btn btn-primary" :class="{ 'save-button__saving': saving }"
    :disabled="saving">
    {{ $t('general.save') }}
  </button>
</div>
</template>
